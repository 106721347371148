import Logo from './logo.png'
export default function Login (props) {

    return (<>
    <div className="flex flex-col bg-orange-200 w-screen h-screen items-center">
      <img src={Logo} />
      <div className="w-1/5 border-2 border-orange-800 px-5 py-2 rounded-xl">
      <p className="text-center text-lg font-semibold">Acceso Administrador</p>
      {props.error===1 ?
        <div className="text-lg text-red-600 font-semibold">Usuario o contrseña incorrecta</div>
        : ''
      }
      {props.error===2 ?
        <div className="text-lg text-red-600 font-semibold">SOLO disponible desde https://www.inscripcion.ar</div>
        : ''
      }
      <div className="flex flex-row text-lg py-2 justify-between">
        <label className="text-lg mr-2">Usuario</label>
          <input 
            type="text"
            className="bg-white w-32 text-black text-base outline-0 border-b-2 border-gray-300 focus:border-orange-800"
            onChange={(e => props.setUsuario(e.target.value))}
            value={props.usuario}
          />
        </div>  
        <div className="flex flex-row text-black py-2 justify-between">
          <label className="text-lg mr-2">Clave</label>
          <input 
            type="password"
            className="bg-white w-32 text-black text-base outline-0 border-b-2 border-gray-300 focus:border-orange-800"
            onChange={(e => props.setPassword(e.target.value))}
            value={props.password}
          />
        </div>  
        <div className="flex flex-row justify-center">
        <button 
          className="mx-auto text-lg mt-2 px-5 py-2 border-2 border-orange-800 rounded-full bg-orange-200 hover:bg-orange-800 hover:text-white"
          onClick={props.login}
        >
          Ingresar
        </button>
        </div>
        </div>
      </div>
      </>)}